<script setup>
    import { onMounted, ref } from "vue"

    defineProps({
        modelValue: [String, Number],
        type: {
            type: String,
            default: "text",
        },
    })

    defineEmits(["update:modelValue"])

    const input = ref(null)

    onMounted(() => {
        if (input.value.hasAttribute("autofocus")) {
            input.value.focus()
        }
    })

    defineExpose({ focus: () => input.value.focus() })
</script>

<template>
    <input
        ref="input"
        :type="type"
        class="rounded-md border-gray-300 shadow-sm focus:border-brand-primary focus:ring-brand-primary dark:border-slate-700 dark:bg-slate-900 dark:text-gray-300"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    />
</template>
